import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  selectItemById,
  selectCurrentFolderItemQueryId,
} from 'ducks/folders/selectors/items'
import {
  selectConversationUnreadCountByQueryId,
  selectEntityCountByQueryId,
} from 'ducks/searches/selectors'
import { toBaseQueryId } from 'ducks/searches/utils/query'
import { useLink } from 'ducks/location/hooks'
import Link from 'redux-first-router-link'

import Menu from '@groovehq/internal-design-system/lib/components/Menu/Menu'
import FolderKindIcon from './FolderKindIcon'
import MoreFolderOptions from './MoreFolderOptions'
import SearchRawCount from '../SearchRawCount'
import { styles } from '../styles'

const FolderItem = props => {
  const { itemId, isSubMenuItem, onClick } = props

  const {
    name,
    queryId: fullQueryId,
    displayCountWhenInactive,
    hideIfZeroConversations,
    linkTo: stateLinkTo,
    displayUnreadIndicator,
    kind,
    highlight,
    showMoreOptions,
    resourceId,
    warning,
  } = useSelector(state => selectItemById(state, itemId))

  const currentFolderQueryId = useSelector(selectCurrentFolderItemQueryId)
  const queryId = toBaseQueryId(fullQueryId)
  const current = queryId === currentFolderQueryId
  const conversationCount = useSelector(state =>
    selectEntityCountByQueryId(state, queryId)
  )
  const unreadCount = useSelector(state =>
    selectConversationUnreadCountByQueryId(state, queryId)
  )

  const linkTo = useLink(stateLinkTo, {
    preserveOrderBy: true,
    removeInvalidOrderBy: true,
  })

  const handleClick = useCallback(
    () => {
      if (onClick) onClick(itemId)
    },
    [onClick, itemId]
  )

  if (hideIfZeroConversations && conversationCount <= 0) {
    return null
  }

  const alert = displayUnreadIndicator && unreadCount > 0
  const shouldDisplayCount =
    !warning && (current || (!current && displayCountWhenInactive))

  return (
    <Menu.Item
      icon={kind && <FolderKindIcon kind={kind} />}
      key={itemId}
      as={Link}
      to={linkTo}
      onClick={handleClick}
      active={current}
      isSubMenuItem={isSubMenuItem}
      alert={alert}
      css={[styles.fixOptionsPosition, styles.item]}
      highlight={highlight}
      moreOptions={showMoreOptions && <MoreFolderOptions id={resourceId} />}
      count={shouldDisplayCount && <SearchRawCount queryId={queryId} />}
    >
      {name}
    </Menu.Item>
  )
}

export default FolderItem
